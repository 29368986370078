import { render, staticRenderFns } from "./AddNewPayment.vue?vue&type=template&id=2130ca6e&"
import script from "./AddNewPayment.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AddNewPayment.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports